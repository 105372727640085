<!-- =========================================================================================
    File Name: ECommerceShop.vue
    Description: eCommerce Shop Page
    ----------------------------------------------------------------------------------------
    Item Name: Vuesax Admin - VueJS Dashboard Admin Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div>
    <div>
      <h3 class="mb-5 mt-10">
        {{ $t("AppStore") }}
      </h3>
    </div>

    <div class="algolia-header mb-4">
      <div class="flex md:items-end items-center justify-between flex-wrap">
        <!-- TOGGLE SIDEBAR BUTTON -->
        <vs-button
          class="lg:hidden"
          size="small"
          @click.stop="toggleFilterSidebar"
          type="filled"
          icon="star"
          >{{ $t("Filter") }}</vs-button
        >
      </div>
    </div>

    <div id="algolia-content-container" class="relative clearfix">
      <vs-sidebar
        class="items-no-padding vs-sidebar-rounded background-absolute"
        parent="#algolia-content-container"
        :click-not-close="clickNotClose"
        :hidden-background="clickNotClose"
        v-model="isFilterSidebarActive"
      >
        <div class="p-6 filter-container">
          <!-- MULTI RANGE -->
          <h6 class="font-bold mb-3">{{ $t("Manage") }}</h6>
          <p>
            <a
              href="#all"
              @click="
                () => {
                  installedOnly = false;
                  search('search');
                }
              "
              class="mb-5"
            >
              <span class="ml-2" :class="{ 'text-primary': true }"
                >{{ $t("All") }} apps</span
              >
            </a>
          </p>
          <p class="mt-2">
            <a
              href="#installed"
              @click="
                () => {
                  installedOnly = true;
                  search('search');
                }
              "
            >
              <span class="ml-2" :class="{ 'text-primary': true }">{{
                $t("YourInstalledApps")
              }}</span>
            </a>
          </p>
          <p class="mt-2">
            <a
              href="#"
              @click.prevent="$router.push('/appstore/history').catch(() => {})"
            >
              <span class="ml-2 text-primary">Histórico de integrações</span>
            </a>
          </p>
          <vs-divider />

          <!-- CATEGORIES -->
          <h6 class="font-bold mb-4">{{ $t("Category") }}</h6>
          <ul>
            <li
              class="flex items-center cursor-pointer py-1"
              @click="
                () => {
                  categoryString = '';
                  refine(categories, 'category', -1);
                }
              "
            >
              <feather-icon
                icon="CircleIcon"
                :svgClasses="[
                  { 'text-primary fill-current': categoryString === '' },
                  'h-5 w-5',
                ]"
              />
              <span
                class="ml-2"
                :class="{ 'text-primary': categoryString === '' }"
                >{{ $t("All") }}</span
              >
            </li>
            <li
              v-for="item in categories"
              :key="item.value"
              class="flex items-center cursor-pointer py-1"
              @click="
                () => {
                  categoryString = item.value;
                  refine(categories, 'category', item);
                }
              "
            >
              <feather-icon
                icon="CircleIcon"
                :svgClasses="[
                  { 'text-primary fill-current': item.isRefined },
                  'h-5 w-5',
                ]"
              />
              <span class="ml-2" :class="{ 'text-primary': item.isRefined }">{{
                item.label
              }}</span>
            </li>
          </ul>

          <vs-divider />

          <!-- TAGS -->
          <h6 class="font-bold mb-4">{{ $t("Tags") }}</h6>
          <ul>
            <li
              class="flex items-center cursor-pointer py-1"
              @click="
                () => {
                  tagsString = '';
                  refine(tags, 'tags', -1);
                }
              "
            >
              <feather-icon
                icon="CircleIcon"
                :svgClasses="[
                  { 'text-primary fill-current': tagsString === '' },
                  'h-5 w-5',
                ]"
              />
              <span
                class="ml-2"
                :class="{ 'text-primary': tagsString === '' }"
                >{{ $t("All") }}</span
              >
            </li>
            <li
              v-for="item in tags"
              :key="item.value"
              class="flex items-center cursor-pointer py-1"
              @click="
                () => {
                  tagsString = item.value;
                  refine(tags, 'tags', item);
                }
              "
            >
              <feather-icon
                icon="CircleIcon"
                :svgClasses="[
                  { 'text-primary fill-current': item.isRefined },
                  'h-5 w-5',
                ]"
              />
              <span class="ml-2" :class="{ 'text-primary': item.isRefined }">{{
                item.label
              }}</span>
            </li>
          </ul>
        </div>
      </vs-sidebar>

      <!-- RIGHT COL -->
      <div :class="{ 'sidebar-spacer-with-margin': clickNotClose }">
        <!-- SEARCH BAR -->
        <div class="relative mb-8">
          <!-- SEARCH INPUT -->
          <vs-input
            class="w-full vs-input-shadow-drop vs-input-no-border d-theme-input-dark-bg"
            :placeholder="$t('Search')"
            v-model="searchString"
            size="large"
            id="searchInput"
          />

          <!-- SEARCH LOADING -->
          <div
            :hidden="!isSearchStalled"
            slot="submit-icon"
            class="absolute top-0 right-0 py-4 px-6"
          >
            <svg
              class="animate-spin"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            >
              <path d="M12 22C6.5 22 2 17.5 2 12S6.5 2 12 2s10 4.5 10 10" />
            </svg>
          </div>

          <!-- SEARCH ICON -->
          <div
            slot="submit-icon"
            class="absolute top-0 right-0 py-4 px-6"
            v-show="!isSearchStalled && searchString.length < 1"
          >
            <feather-icon icon="SearchIcon" svgClasses="h-6 w-6" />
          </div>

          <!-- CLEAR INPUT ICON -->
          <div
            slot="reset-icon"
            class="absolute top-0 right-0 py-4 px-6"
            v-show="!isSearchStalled && searchString.length > 0"
          >
            <feather-icon
              icon="XIcon"
              svgClasses="h-6 w-6 cursor-pointer"
              @click="search(null)"
            />
          </div>
        </div>

        <!-- SEARCH RESULT -->
        <!-- GRID VIEW -->
        <template>
          <div
            class="vx-row w-full items-center justify-center"
            v-show="nbApps == 0"
          >
            {{ $t("NoRecordsFounded") }}
          </div>
          <div class="items-grid-view vx-row match-height content-between">
            <div
              class="vx-col lg:w-1/3 sm:w-1/2 w-full"
              v-for="(item, l) in apps"
              :key="l"
            >
              <item-grid-view :item="item">
                <template slot="action-buttons">
                  <div class="flex flex-wrap mr-5 float-right">
                    <div
                      class="px-2 py-1 rounded items-center justify-center text-white cursor-pointer"
                      :class="{
                        'bg-primary': item.appInstalled.length > 0,
                        'bg-success': item.appInstalled.length == 0,
                      }"
                      @click="appButtonClicked(item, l)"
                    >
                      <span
                        class="text-sm font-semibold"
                        v-if="item.appInstalled.length > 0"
                      >
                        <feather-icon
                          icon="SettingsIcon"
                          svgClasses="h-4 w-4"
                        />
                        {{ $t("Manage") }}
                      </span>
                      <span class="text-sm font-semibold" v-else>
                        <feather-icon
                          icon="DownloadIcon"
                          svgClasses="h-4 w-4"
                        />
                        {{ $t("Install") }}
                      </span>
                    </div>
                  </div>
                </template>
              </item-grid-view>
            </div>
          </div>
        </template>

        <!-- PAGINATION -->
      </div>
    </div>
    <div class="vx-row w-full" v-show="nbApps > 0">
      <vs-pagination
        :total="nbPages"
        :max="nbTotalApps"
        v-model="currentPage"
        @click="currentPage++"
        style="width: 100%; justify-content: flex-end"
      />
    </div>
    <vs-popup
      :title="$t('Install') + ' ' + $t('App')"
      :active.sync="popupActive"
    >
      <div
        class="flex-wrap justify-left"
        ref="popupContent"
        v-if="currentlItem != null"
      >
        <div class="w-full">
          <div class="float-right">
            <img
              :src="currentlItem.img"
              :alt="currentlItem.name"
              class="float-left mt-1 ml-5"
              style="opacity: 0.5; max-height: 80px"
            />
          </div>
          <div class="floar-left">
            <h2 class="font-semibold">{{ currentlItem.name }}</h2>
            <p class="item-description text-sm">
              {{ currentlItem.description }}
            </p>
          </div>
          <div class="clear-right"></div>
        </div>
        <div class="w-full">
          <vs-divider />
          <vs-collapse accordion type="border">
            <vs-collapse-item
              :key="k + '-listInstall'"
              v-for="(currentItemAppInstalled, k) in currentlItem.appInstalled"
              :id="'installeditem-' + k"
            >
              <div slot="header">
                {{
                  currentItemAppInstalled.name ||
                  currentlItem.name + " " + (k + 1)
                }}
              </div>
              <div
                class="w-full mb-2"
                v-if="
                  getParsedPosInstructions(
                    currentlItem,
                    currentItemAppInstalled
                  ) !== false
                "
              >
                <b>{{ $t("HowToSetup") }}</b>
                <p
                  v-html="
                    getParsedPosInstructions(
                      currentlItem,
                      currentItemAppInstalled
                    )
                  "
                ></p>
              </div>
              <hr
                class="mb-5 mt-5"
                v-if="
                  getParsedPosInstructions(
                    currentlItem,
                    currentItemAppInstalled
                  ) !== false
                "
              />
              <div class="w-full">
                <b>{{ $t("Edit") }}</b>
                <div class="w-full mb-5">
                  <small>{{ $t("AppName") }}</small>
                  <vs-input
                    class="w-full"
                    v-model="currentItemAppInstalled.name"
                  />
                </div>
              </div>
              <div class="w-full mb-5">
                <small>Selecionar os momentos de envio de integração</small>
                <v-select
                  :value="currentItemAppInstalled.params.triggerIntegration"
                  :options="triggerIntegrationArray"
                  class="w-full"
                  multiple
                  @input="setSelectValue($event, k, 'triggerIntegration')"
                />
              </div>
              <div
                v-for="(item, i) in installSelects"
                :key="'select' + i"
                class="w-full mb-5"
              >
                <small>{{
                  item.name.charAt(0).toUpperCase() + item.name.slice(1)
                }}</small>
                <v-select
                  v-if="item.name === 'chat'"
                  :value="getChatbotObject(currentItemAppInstalled.chat)"
                  :options="item.options"
                  :dir="$vs.rtl ? 'rtl' : 'ltr'"
                  class="w-full"
                  :multiple="false"
                  @input="setSelectValue($event, k, 'chat')"
                />
                <v-select
                  v-else-if="item.name === 'intentions'"
                  :value="currentItemAppInstalled.params[item.name]"
                  :options="currentChatIntentions(currentItemAppInstalled.chat)"
                  :dir="$vs.rtl ? 'rtl' : 'ltr'"
                  class="w-full"
                  :multiple="item.multiple"
                  @input="setSelectValue($event, k, 'intentions')"
                />
                <v-select
                  v-else-if="item.name === 'entities'"
                  :value="currentItemAppInstalled.params[item.name]"
                  :options="currentChatEntities(currentItemAppInstalled.chat)"
                  :dir="$vs.rtl ? 'rtl' : 'ltr'"
                  class="w-full"
                  :multiple="item.multiple"
                  @input="setSelectValue($event, k, 'entities')"
                />
                <v-select
                  v-else-if="item.name === 'keyEntities'"
                  :value="currentItemAppInstalled.params[item.name]"
                  :options="
                    currentChatKeyEntities(currentItemAppInstalled.chat)
                  "
                  :dir="$vs.rtl ? 'rtl' : 'ltr'"
                  class="w-full"
                  :multiple="item.multiple"
                  @input="setSelectValue($event, k, 'keyEntities')"
                />
                <v-select
                  v-else-if="item.name === 'origin'"
                  :value="currentItemAppInstalled.params[item.name]"
                  :options="originData"
                  class="w-full"
                  multiple
                  @input="setSelectValue($event, k, 'origin')"
                />
                <v-select
                  v-else
                  :value="currentItemAppInstalled.params[item.name]"
                  :options="item.options"
                  :dir="$vs.rtl ? 'rtl' : 'ltr'"
                  class="w-full"
                  :multiple="item.multiple"
                  @input="setSelectValue($event, k, item.name)"
                />
              </div>
              <div
                v-for="(item, i) in installInputs"
                :key="'input' + i"
                class="w-full mb-5"
              >
                <small>{{
                  item.name.charAt(0).toUpperCase() + item.name.slice(1)
                }}</small>
                <vs-input
                  class="w-full"
                  v-model="currentItemAppInstalled.params[item.name]"
                />
              </div>
              <div class="w-full mb-5">
                <small>Enviar operador na integração?</small>
                <vs-switch
                  color="#28c76f"
                  v-model="currentItemAppInstalled.sendOperatorId"
                />
              </div>
              <div class="w-full mt-5">
                <vs-button
                  color="primary"
                  type="filled"
                  size="small"
                  @click="save(currentItemAppInstalled)"
                  >{{ $t("Edit") }}</vs-button
                >
                <span
                  class="float-right text-danger cursor-pointer"
                  @click="unsintallAsk(currentItemAppInstalled, k)"
                  >{{ $t("Delete") }}</span
                >
              </div>
            </vs-collapse-item>
            <!-- Install app "New" button -->
            <vs-collapse-item
              id="installationitem"
              :open="currentlItem.appInstalled.length === 0"
            >
              <div slot="header">
                <feather-icon icon="PlusIcon" svgClasses="h-4 w-4" />
                {{ $t("New") }}
              </div>
              <b v-if="currentlItem.preInstallInstructions">{{
                $t("HowToSetup")
              }}</b>
              <p
                v-if="currentlItem.preInstallInstructions"
                v-html="currentlItem.preInstallInstructions"
              ></p>
              <vs-divider />
              <div class="w-full mb-5">
                <small>Selecionar os momentos de envio de integração</small>
                <v-select
                  :value="installValues.params.triggerIntegration"
                  :options="triggerIntegrationArray"
                  class="w-full"
                  multiple
                  @input="setTriggerIntegrationCreate($event)"
                />
              </div>
              <div
                v-for="(item, i) in installSelects"
                :key="'selectinstall' + i"
                class="w-full mb-5"
              >
                <small>{{
                  item.name.charAt(0).toUpperCase() + item.name.slice(1)
                }}</small>
                <v-select
                  v-if="item.name === 'chat'"
                  :value="item.value"
                  :options="chatbotData"
                  :dir="$vs.rtl ? 'rtl' : 'ltr'"
                  class="w-full"
                  :multiple="false"
                  @input="setInstallValue($event, item, i)"
                />
                <v-select
                  v-else-if="item.name === 'intentions'"
                  :value="item.value"
                  :options="currentChatIntentions(null)"
                  :dir="$vs.rtl ? 'rtl' : 'ltr'"
                  class="w-full"
                  :multiple="item.multiple"
                  @input="setInstallValue($event, item, i)"
                />
                <v-select
                  v-else-if="item.name === 'entities'"
                  :value="item.value"
                  :options="currentChatEntities(null)"
                  :dir="$vs.rtl ? 'rtl' : 'ltr'"
                  class="w-full"
                  :multiple="item.multiple"
                  @input="setInstallValue($event, item, i)"
                />
                <v-select
                  v-else-if="item.name === 'keyEntities'"
                  :value="item.value"
                  :options="currentChatKeyEntities(null)"
                  :dir="$vs.rtl ? 'rtl' : 'ltr'"
                  class="w-full"
                  :multiple="item.multiple"
                  @input="setInstallValue($event, item, i)"
                />
                <v-select
                  v-else-if="item.name === 'origin'"
                  :value="installValues.params.origin"
                  :options="originData"
                  :dir="$vs.rtl ? 'rtl' : 'ltr'"
                  class="w-full"
                  multiple
                  @input="setOriginCreate($event)"
                />
                <v-select
                  v-else
                  :value="item.value"
                  :options="item.options"
                  :dir="$vs.rtl ? 'rtl' : 'ltr'"
                  class="w-full"
                  :multiple="item.multiple"
                  @input="setInstallValue($event, item, i)"
                />
              </div>
              <div
                v-for="(item, i) in installInputs"
                :key="'inputistall' + i"
                class="w-full mb-5"
              >
                <small>{{
                  item.name.charAt(0).toUpperCase() + item.name.slice(1)
                }}</small>
                <vs-input
                  class="w-full"
                  @input="setInstallValue($event, item, i)"
                  v-model="item.value"
                />
              </div>
              <div class="w-full mb-5">
                <small>{{ $t("AppName") }}</small>
                <!-- <vs-input class="w-full" v-model="installValues.name" /> -->
                <input class="custom-input" v-model="installValues.name" />
              </div>
              <div class="w-full mb-5">
                <small>{{ $t("SendOperatorIntegration") }}</small>
                <vs-switch
                  color="#28c76f"
                  v-model="installValues.sendOperatorId"
                />
              </div>
              <vs-divider />
              <vs-button
                color="success"
                type="filled"
                @click="save(installValues)"
                >{{ $t("Install") }}</vs-button
              >
            </vs-collapse-item>
          </vs-collapse>
        </div>
        <br class="clear-both" />
      </div>
    </vs-popup>
  </div>
</template>

<script>
import vSelect from "vue-select";
export default {
  components: {
    ItemGridView: () => import("./components/ItemGridView.vue"),
    "v-select": vSelect,
  },
  data() {
    return {
      apps: [],
      tags: [],
      categories: [],
      nbTotalApps: 10,
      nbPages: 0,
      nbApps: 0,
      currentPage: 1,
      searchString: "",
      categoryString: "",
      tagsString: "",
      isSearchStalled: false,
      installedOnly: false,
      popupActive: false,
      installInputs: [],
      installSelects: [],
      currentlItem: null,
      installValues: {
        params: {
          triggerIntegration: [this.$t("triggerIntegrationLabelOpening")],
        },
        sendOperatorId: false,
        triggerIntegration: [this.$t("triggerIntegrationLabelOpening")],
      },
      chatbotData: [],
      originData: ["Inbound", "Outbound", "Campanha"],
      chatbotIntentions: [],
      chatbotEntities: [],
      chatbotKeyEntities: [],
      isEdit: false,
      toDelete: {},
      isFilterSidebarActive: true,
      clickNotClose: true,
      windowWidth: window.innerWidth,
      selectedChat: null,
      triggerIntegration: [],
      triggerIntegrationArray: [
        this.$t("triggerIntegrationLabelOpening"),
        this.$t("triggerIntegrationLabelClosing"),
        this.$t("triggerIntegrationLabelCampaign"),
        //this.$t("triggerIntegrationLabelTransfer"),
      ],
    };
  },
  watch: {
    currentPage: function () {
      this.getApps();
    },
  },
  methods: {
    accIntentions() {
      let intentionsData = [];

      this.chatbotIntentions.forEach((i) => {
        i.intentions.forEach((e) => {
          intentionsData.push(e);
        });
      });

      return intentionsData ? intentionsData.concat(["outros"]) : ["outros"];
    },
    accEntities() {
      let entitiesData = [];

      this.chatbotEntities.forEach((i) => {
        i.entities.forEach((e) => {
          entitiesData.push(e);
        });
      });

      return entitiesData;
    },
    currentChatIntentions(chat) {
      if (!chat) {
        chat = this.selectedChat;
        if (!chat) return [];
      }
      const intentionsData = this.chatbotIntentions.find(
        (x) => x.chat === chat
      );

      return intentionsData
        ? intentionsData.intentions.concat(["outros"])
        : ["outros"];
    },
    currentChatEntities(chat) {
      if (!chat) chat = this.selectedChat;
      const entitiesData = this.chatbotEntities.find((x) => x.chat === chat);
      return entitiesData ? entitiesData.entities : [];
    },
    currentChatKeyEntities(chat) {
      if (!chat) chat = this.selectedChat;
      const entitiesData = this.chatbotKeyEntities.find((x) => x.chat === chat);
      return entitiesData ? entitiesData.entities : [];
    },
    unsintallAsk(item, key) {
      this.toDelete = { item: item, key: key };
      this.$vs.dialog({
        id: "teste",
        type: "confirm",
        color: "danger",
        acceptText: this.$t("Delete"),
        cancelText: this.$t("Cancelar"),
        title: this.$t("Confirm"),
        text: this.$t("ConfirmRemove"),
        accept: this.unsintall,
      });
    },
    unsintall() {
      window.analytics.track(
        "Excluir Integração",
        {},
        { groupId: this.$store.state.acc.current_acc.id }
      );
      this.$http({
        method: "delete",
        url: "/g/product/apps/accapp/" + this.toDelete.item._id,
        params: {
          acc: this.$store.state.acc.current_acc.id,
        },
      })
        .then((response) => {
          if (response.data.status === 1) {
            this.toDelete = {};
            this.getApps();
            this.popupActive = false;
            this.$vs.notify({
              title: this.$t("Success"),
              text: this.$t("AppDeletedSuccess"),
              iconPack: "feather",
              icon: "icon-check",
              color: "success",
              position: "top-right",
              time: 4000,
            });
          }
        })
        .catch(() => {});
    },
    setSelectValue(e, key, name) {
      switch (name) {
        case "chat":
          this.currentlItem.appInstalled[key].chat = e.id;
          break;
        case "keyEntities":
          this.currentlItem.appInstalled[key].params[name] = e;
          this.currentlItem.appInstalled[key].entities = this.currentlItem
            .appInstalled[key].entities
            ? this.currentlItem.appInstalled[key].entities.concat(e)
            : e;
          break;
        default:
          if (Array.isArray(e)) {
            this.currentlItem.appInstalled[key].params[name] = e;
            this.currentlItem.appInstalled[key][name] = e;
          } else {
            this.currentlItem.appInstalled[key].params[name] = e;
            this.currentlItem.appInstalled[key][name] = e;
          }
          break;
      }
    },
    setTriggerIntegrationCreate(e) {
      this.installValues.params.triggerIntegration = e;
      this.installValues.triggerIntegration = e;
    },
    setOriginCreate(e) {
      this.installValues.params.origin = e;
    },
    setInstallValue(e, item) {
      if (Array.isArray(e)) {
        this.installValues[item.name] = [];
        for (var i in e)
          this.installValues[item.name].push(e[i].id ? e[i].id : e[i]);
        this.installValues[item.name] = this.installValues[item.name];
      } else this.installValues.params[item.name] = e;

      this.selectedChat =
        this.installValues &&
        this.installValues.params &&
        this.installValues.params.chat
          ? this.installValues.params.chat.id
          : null;
    },
    handleWindowResize(event) {
      this.windowWidth = event.currentTarget.innerWidth;
      this.setSidebarWidth();
    },
    setSidebarWidth() {
      if (this.windowWidth < 992) {
        this.isFilterSidebarActive = this.clickNotClose = false;
      } else {
        this.isFilterSidebarActive = this.clickNotClose = true;
      }
    },
    toggleFilterSidebar() {
      if (this.clickNotClose) return;
      this.isFilterSidebarActive = !this.isFilterSidebarActive;
    },
    getChatbotObject(id) {
      return this.chatbotData.filter((x) => x.id === id);
    },
    appButtonClicked(item, l) {
      this.installInputs = [];
      this.installSelects = [];
      this.isEdit = false;

      if (
        typeof item.appInstalled !== undefined &&
        item.appInstalled.length > 0
      ) {
        this.isEdit = true;
        item.appInstalled.forEach((el, i) => {
          if (!el.params.triggerIntegration)
            item.appInstalled[i].params.triggerIntegration = [
              this.$t("triggerIntegrationLabelOpening"),
            ];
        });
      } else
        this.installValues = {
          params: {
            triggerIntegration: [this.$t("triggerIntegrationLabelOpening")],
          },
          sendOperatorId: false,
          triggerIntegration: [this.$t("triggerIntegrationLabelOpening")],
        };

      this.installValues.name = item.name;

      if (
        item.installation.fields != null &&
        item.installation.fields.length > 0
      ) {
        for (var i in item.installation.fields) {
          if (item.installation.fields[i].type === "select") {
            this.installSelects.push({
              name: item.installation.fields[i].name,
              options: item.installation.fields[i].options || [],
              multiple: item.installation.fields[i].multiple,
              required: item.installation.fields[i].required,
              value:
                this.installValues.params[item.installation.fields[i].name],
            });
          } else if (item.installation.fields[i].type === "input") {
            this.installInputs.push({
              name: item.installation.fields[i].name,
              value:
                this.installValues.params[item.installation.fields[i].value] ||
                "",
            });
          }
        }
        this.apps[l] = item;
        this.currentlItem = item;
        this.popupActive = true;
      } else this.save();
    },
    save(item) {
      window.analytics.track(
        typeof item._id !== "undefined"
          ? "Editar Integração"
          : "Ativar Integração",
        {},
        { groupId: this.$store.state.acc.current_acc.id }
      );
      let params = item;
      params.app = this.currentlItem._id;
      let url = "/g/product/apps/accapp/install";
      if (typeof item._id !== "undefined")
        url = "/g/product/apps/accapp/" + item._id;
      this.$http({
        method: typeof item._id !== "undefined" ? "put" : "post",
        url: url,
        data: params,
        params: {
          acc: this.$store.state.acc.current_acc.id,
        },
      })
        .then((response) => {
          if (response.data.status === 1) {
            if (typeof item._id === "undefined") {
              this.currentlItem.appInstalled.push(response.data.data);
              this.installSelects = [];
              this.installInputs = [];
              // set installValues to standart values
              this.installValues = {
                params: {
                  triggerIntegration: [
                    this.$t("triggerIntegrationLabelOpening"),
                  ],
                },
                sendOperatorId: false,
                triggerIntegration: [this.$t("triggerIntegrationLabelOpening")],
              };
              this.popupActive = false;
            }
            this.$vs.notify({
              title: this.$t("Success"),
              text:
                typeof item._id === "undefined"
                  ? this.$t("AppInstalledSuccess")
                  : this.$t("AppEditedSuccess"),
              iconPack: "feather",
              icon: "icon-check",
              color: "success",
              position: "top-right",
              time: 4000,
            });
          }
        })
        .catch((error) => {
          if (typeof error.request !== "undefined")
            if (typeof error.request.response !== "undefined")
              error.message = JSON.parse(error.request.response).message;
            else error.message = this.$t("UnexpectedErrorLoadX", ["Leads"]);
          this.$vs.notify({
            title: this.$t("UnexpectedError"),
            text: error.message,
            iconPack: "feather",
            icon: "icon-alert-circle",
            color: "warning",
            position: "top-right",
            time: 4000,
          });
        });
    },
    search(e) {
      if (e == null) this.searchString = "";
      this.getApps();
    },
    arraySearch(array, key, value) {
      for (var i = 0; i < array.length; i++) {
        if (array[i][key] === value) {
          return i;
        }
      }
      return -1;
    },
    refine(array, refineType, item) {
      for (var j in array) array[j]["isRefined"] = false;

      this.search("s");
      if (item.value === -1) return;
      var i = this.arraySearch(array, "value", item.value);
      if (i >= 0)
        if (typeof array[i].isRefined !== "undefined")
          array[i].isRefined = !array[i].isRefined;
    },
    getCategories() {
      this.$http
        .get("/g/product/apps/list/filters", {
          params: {
            acc: this.$store.state.acc.current_acc.id,
          },
        })
        .then((response) => {
          if (response.data.data.length > 0) {
            for (var i in response.data.data) {
              if (
                this.arraySearch(
                  this.categories,
                  "value",
                  response.data.data[i].category
                ) === -1
              )
                this.categories.push({
                  value: response.data.data[i].category,
                  label: response.data.data[i].category,
                  isRefined: false,
                });
              for (var j in response.data.data[i].tags) {
                if (
                  this.arraySearch(
                    this.tags,
                    "value",
                    response.data.data[i].tags[j]
                  ) === -1
                )
                  this.tags.push({
                    value: response.data.data[i].tags[j],
                    label: response.data.data[i].tags[j],
                    isRefined: false,
                  });
              }
            }
          }
        })
        .catch(() => {});
    },
    getApps() {
      this.$http
        .get("/g/product/apps/list", {
          params: {
            acc: this.$store.state.acc.current_acc.id,
            search: this.searchString.length > 0 ? this.searchString : null,
            category:
              this.categoryString.length > 0 ? this.categoryString : null,
            tag: this.tagsString.length > 0 ? this.tagsString : null,
            installedOnly: this.installedOnly,
            page: this.currentPage,
          },
        })
        .then((response) => {
          this.isSearchStalled = false;
          var apps = response.data.data.apps;
          for (var i in apps) {
            for (var j in apps[i].appInstalled) {
              if (typeof apps[i].appInstalled[j].params !== "object")
                apps[i].appInstalled[j].params = {};
            }
          }
          this.apps = apps;

          this.nbPages = Math.ceil(response.data.data.count / this.nbTotalApps);
          this.nbApps = response.data.data.count;
        })
        .catch(() => {});
    },
    getChats() {
      this.$http
        .get("/p/chat/list", {
          params: {
            acc: "" + this.$store.state.acc.current_acc.id + "",
          },
        })
        .then((response) => {
          var ArrayChats = [];
          for (var i = 0; i < response.data.data.length; i++) {
            this.chatbotIntentions.push({
              chat: response.data.data[i]._id,
              intentions: response.data.data[i].intentions,
            });
            this.chatbotEntities.push({
              chat: response.data.data[i]._id,
              entities: response.data.data[i].entities,
            });
            this.chatbotKeyEntities.push({
              chat: response.data.data[i]._id,
              entities: response.data.data[i].keyEntities,
            });

            ArrayChats.push({
              id: response.data.data[i]._id,
              label: response.data.data[i].name,
            });
          }

          this.chatbotData = ArrayChats;
        })
        .catch((error) => {
          if (typeof error.request !== "undefined")
            if (typeof error.request.response !== "undefined")
              error.message = JSON.parse(error.request.response).message;
            else error.message = this.$t("UnexpectedErrorLoadX", ["chatbots"]);
          this.$vs.notify({
            title: this.$t("UnexpectedError"),
            text: error.message,
            iconPack: "feather",
            icon: "icon-alert-circle",
            color: "warning",
            position: "top-right",
            time: 4000,
          });
        });
    },
    getParsedPosInstructions(item, installedItem) {
      if (
        item.postInstallInstructions == null ||
        item.postInstallInstructions.length === 0
      )
        return false;

      var str = item.postInstallInstructions;
      var regex = /{{(.*?)}}/g;
      var m = "";
      while ((m = regex.exec(str))) {
        str = str.replace(m[0], installedItem[m[1]]);
      }
      return str;
    },
  },
  created() {
    this.$nextTick(() => {
      window.addEventListener("resize", this.handleWindowResize);
    });
    this.setSidebarWidth();
  },
  mounted() {
    this.getApps();
    this.getChats();
    this.getCategories();

    var self = this;
    var typingTimer;
    var doneTypingInterval = 800;
    var input = document.getElementById("searchInput");

    input.addEventListener("keyup", function () {
      self.isSearchStalled = true;
      clearTimeout(typingTimer);
      typingTimer = setTimeout(function () {
        self.search("search");
      }, doneTypingInterval);
    });

    input.addEventListener("keydown", function () {
      clearTimeout(typingTimer);
    });
  },
  beforeDestroy: function () {
    window.removeEventListener("resize", this.handleWindowResize);
  },
};
</script>

<style lang="scss" scoped>
.vs-sidebar--items {
  display: table !important;
}
.con-vs-dialog {
  z-index: 2147483647 !important;
}
#algolia-instant-search-demo {
  .algolia-header {
    .algolia-filters-label {
      width: calc(260px + 2.4rem);
    }
  }

  #algolia-content-container {
    .vs-sidebar {
      position: relative;
      float: left;
    }
  }

  .algolia-search-input-right-aligned-icon {
    padding: 1rem 1.5rem;
  }

  .algolia-price-slider {
    min-width: unset;
  }

  .item-view-primary-action-btn {
    color: #2c2c2c !important;
    background-color: #f6f6f6;
    min-width: 50%;
  }

  .item-view-secondary-action-btn {
    min-width: 100px;
  }
}

.theme-dark {
  #algolia-instant-search-demo {
    #algolia-content-container {
      .vs-sidebar {
        background-color: #10163a;
      }
    }
  }
}

@media (min-width: 992px) {
  .vs-sidebar-rounded {
    .vs-sidebar {
      border-radius: 0.5rem;
    }
    .vs-sidebar--items {
      border-radius: 0.5rem;
    }
  }
}

@media (max-width: 992px) {
  #algolia-content-container {
    .vs-sidebar {
      position: absolute !important;
      float: none !important;
    }
  }
}

.custom-input {
  width: 100%;
  padding: 0.7rem !important;
  font-size: 1rem !important;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 5px;

  &:focus {
    border: 1px solid rgba(var(--vs-primary), 1) !important;
  }
}
</style>
